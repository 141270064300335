<template>
  <div class="content">
    
    <div class="list">
      <div class="list_title">
        <span :class="active == 1 ? 'active' : ''" @click="changeFun(1)"
          >收入</span
        >
        <span :class="active == 2 ? 'active' : ''" @click="changeFun(2)"
          >支出</span
        >
      </div>

      <div class="list_con list_con2" v-if="active == 1">
        <ul>
          <li v-for="(item, index) of list" :key="index">
            <div class="list_con_left">
              <div class="list_con_left_head">{{ item.title }}</div>
              <div class="list_con_left_time">{{ item.create_time }}</div>
            </div>
            <div class="list_con_right list_con_right2">
              <span>+{{ item.integral }}</span>
              <span>积分</span>
            </div>
          </li>
        </ul>
        <div class="loading" v-loading="loading" v-show="loading"></div>
        <el-empty description="暂无收入" v-if="list_total == 0 && loading==false"></el-empty>
        <page
          v-if="list_total > limit"
          :page="page"
          :limit="limit"
          :total="list_total"
          ref="page"
        ></page>
      </div>

      <div class="list_con" v-if="active == 2">
        <ul>
          <li v-for="(item, index) of list" :key="index">
            <div class="list_con_left">
              <div class="list_con_left_head">{{ item.title }}</div>
              <div class="list_con_left_time">{{ item.create_time }}</div>
            </div>
            <div class="list_con_cen" v-if="item.source==7">
              <div class="list_con_thumb">
                <img v-lazy="item.exchanges.goods_thumb" />
              </div>
              <div class="list_con_express">
                <p>订单编号：{{item.exchanges.order_no}}</p>
                <p>收货人：<span v-if="item.exchanges.name">{{item.exchanges.name}}</span><span class="colorblue" v-else @click="subexpressFun(item.exchanges.order_no)">去填写</span></p>
                <p>手机号：<span v-if="item.exchanges.mobile">{{item.exchanges.mobile}}</span><span class="colorblue" v-else @click="subexpressFun(item.exchanges.order_no)">去填写</span></p>
                <p>
                  地址：<span v-if="item.exchanges.address">{{item.exchanges.address}}</span><span class="colorblue" v-else @click="subexpressFun(item.exchanges.order_no)">去填写</span>
                </p>
                <b>快递单号：{{item.exchanges.express?item.exchanges.express:'暂无信息'}}</b>
              </div>
            </div>
            <div :class="item.source==8?'list_con_right list_con_right2':'list_con_right'">
              <span>-{{item.integral}}</span>
              <span>积分</span>
            </div>
          </li>
       
        </ul>
        <div class="loading" v-loading="true" v-show="loading"></div>
        <el-empty description="暂无支出" v-if="list_total == 0 && loading==false"></el-empty>
        <page
          v-if="list_total > limit"
          :page="page"
          :limit="limit"
          :total="list_total"
          ref="page"
        ></page>
      </div>
    </div>

    <div class="list">
      <div class="list_title list_title2">
        <span class="active">做任务赚积分</span>
        <!-- <a @click="toDetail">赚取更多积分>></a> -->
      </div>
      <div class="list_omg">
        <div class="list_omg_one">
          <div class="yuan"><span>{{info.video}}</span><span>积分</span></div>
          <h2>学习新课程</h2>
          <span>{{info.is_video==1?1:0}}/1</span>
          <div class="btn btn2" v-if="info.is_video==1">已完成</div>
          <div class="btn" v-else @click="toVideo">去完成</div>
        </div>
        <div class="list_omg_one">
          <div class="yuan"><span>{{info.task}}</span><span>积分</span></div>
          <h2>提交作业</h2>
          <span>{{info.task_ok_num}}/1</span>
          <div class="btn btn2" v-if="info.task_ok_num>=1">已完成</div>
          <div class="btn" v-else @click="toVideo">去完成</div>
        </div>
        <div class="list_omg_one">
          <div class="yuan"><span>{{info.goods}}</span><span>积分</span></div>
          <h2>获得优秀作业</h2>
          <span>{{info.task_good_num}}/1</span>
          <div class="btn btn2" v-if="info.task_good_num>=1">已获得</div>
          <div class="btn" v-else @click="toVideo">去获得</div>
        </div>
       
        <div class="list_omg_one">
          <div class="yuan"><span>{{info.rankthree}}</span><span>积分</span></div>
          <h2>获得“造字达人”称号</h2>
          <span>参加周末卷卷堂</span>
          <div class="btn btn2" v-if="info.daren==1">已获得</div>
          <div class="btn" v-else @click="toArrondi">去获得</div>
        </div>
        <div class="list_omg_one">
          <div class="yuan"><span>{{info.ranktwo}}</span><span>积分</span></div>
          <h2>获得“造字大师”称号</h2>
          <span>参加周末卷卷堂</span>
          <div class="btn btn2" v-if="info.dashi==1">已获得</div>
          <div class="btn" v-else @click="toArrondi">去获得</div>
        </div>
         <div class="list_omg_one">
          <div class="yuan"><span>{{info.rankone}}</span><span>积分</span></div>
          <h2>获得“造字王者”称号</h2>
          <span>参加周末卷卷堂</span>
          <div class="btn btn2" v-if="info.wangzhe==1">已获得</div>
          <div class="btn" v-else @click="toArrondi">去获得</div>
        </div>
      </div>
    </div>

  <el-drawer
      :visible.sync="status"
      direction="ttb"
      :with-header="false"
      :destroy-on-close="true"
    >
        <integralPop :number="number" @close="closeFun" type="0"></integralPop>
    </el-drawer>

  </div>
</template>

<script>
import integralPop from '../../components/integralPop.vue';
import page from "../../components/page.vue";
export default {
  name: "instegralRecord",
  data() {
    return {
    
      active: 1,
      page: 1,
      limit: 5,
      list: [],
      list_total: 0,
      status:false,
      number:'',
      info:{},
      loading:true
    };
  },
  components: {
    page,
    integralPop
  },
  mounted() {
    if(!this.$parent.headers.token){
        this.$parent.$parent.loginShow();
    }else{
      this.listFun();
    }
    this.getInfo();
  },
  methods: {

    listFun() {
      this.$api.POST(
        this.$face.integral_record,
        {
          types: this.active,
          page: this.page,
          limit: this.limit,
        },
        (res) => {
          this.list = res.data.lists;
          this.list_total = res.data.total;
          this.loading=false;
        }
      );
    },
    getInfo() {
      this.$api.POST(
        this.$face.integral_task,{},(res) => {
          this.info = res.data;
        }
      );
    },
    subexpressFun(order_no){
      this.number=order_no;
      this.status=true;
    },
    changeFun(type) {
      this.active = type;
      this.page=1;
      this.list=[];
      this.list_total=0;
      this.loading=true;
      this.listFun();
    },
    closeFun(type){
      this.status=false;
      if(type==1){
        this.listFun();
      }
    },
    toVideo(){
      let routeData = this.$router.resolve({
          name: "refresh",
          query: {
            usernext: 'video',
          },
        });
        window.open(routeData.href, "_blank");
    },
    toRecord() {
       if(!this.headers.token){
        this.$parent.loginShow();
        return ;
      }
      this.$router.push({
        name: "integralRecord",
      });
    },
    toRain() {
        this.$router.push({
            name: "integralIntro",
        });
    },
    toArrondi(){
      let routeData = this.$router.resolve({
          name: "refresh",
          query: {
            usernext: 'arrondi',
          },
        });
        window.open(routeData.href, "_blank");
    },

    toDetail() {
      let routeUrl = this.$router.resolve({
        path: "/agreement",
        query: {
          id: 14,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  watch: {
    "$store.state.userInfo"(newval) {
      this.listFun();
    },
  }
};
</script>

<style scoped>
.content >>> .el-drawer {
  height: 100% !important;
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0px rgb(0 0 0 / 0%),
    0 0px 0px 0px rgb(0 0 0 / 0%) !important;
}
/* .banner {
  width: 100%;
  height: 120px;
  background-image: linear-gradient(to right, #aeaeff, #5857ff);
  text-align: center;
  line-height: 120px;
  font-size: 42px;
  color: #fff;
} */

.list {
  width: 1008px;
  margin: 39px auto;
}
.list .list_title {
  height: 39px;
  line-height: 39px;
  font-size: 18px;
  color: #777;
  border-bottom: 1px #e0e2e5 solid;
}
.list .list_title span {
  display: inline-block;
  padding: 0 2px;
  margin-right: 43px;
  cursor: pointer;
}
.list .list_title span.active {
  border-bottom: 2px #5857ff solid;
  height: 37px;
  line-height: 39px;
}
.list .list_title2 a{ float: right; font-size: 16px; color: #999; cursor: pointer;}
.list_con {
  width: 1000px;
  margin: 35px auto;
}
.list_con li {
  background: #fff;
  width: 1000px;
  box-sizing: border-box;
  padding: 20px 15px;
  border-radius: 15px;
  min-height: 76px;
  overflow: hidden;
  margin-bottom: 15px;
  position: relative;
}
.list_con li .list_con_left {
  float: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  height: 100%;
  width: 175px;
}
.list_con li .list_con_left .list_con_left_head {
  font-size: 16px;
  color: #4d4d4d;
}
.list_con li .list_con_left .list_con_left_time {
  color: #9a9a9a;
  font-size: 12px;
  position: absolute;
  left: 15px;
  bottom: 20px;
}
.list_con_cen {
  float: left;
  margin-left: 30px;
}
.list_con_cen .list_con_thumb {
  height: 130px;
  width: 130px;
  float: left;
  position: relative;
  overflow: hidden;
}
.list_con_cen .list_con_thumb img {
  height: 130px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.list_con_express {
  float: left;
  margin-left: 36px;
  max-width: 450px;
}
.list_con_express p {
  font-size: 14px;
  color: #626262;
  line-height: 26px;
  word-wrap: break-word;
  word-break: break-all;
}
.list_con_express b {
  color: #4d4d4d;
  margin-top: 10px;
  clear: both;
  line-height: 26px;
}
.list_con_right {
  float: right;
  margin-top: 64px;
}
.list_con_right span:nth-child(1) {
  font-size: 26px;
  color: #6261ff;
  margin-top: 2px;
}
.list_con_right span:nth-child(2) {
  font-size: 12px;
  color: #4d4d4d;
  margin-left: 5px;
}
.list_con_right2 {
  margin-top: 5px;
}
.list_con2 li {
  height: 73px;
  padding-left: 37px;
  padding-right: 54px;
}
.list_con2 li .list_con_left {
  width: auto;
  display: block;
  line-height: 36px;
}
.list_con2 li .list_con_left .list_con_left_head {
  float: left;
}
.list_con2 li .list_con_left .list_con_left_time {
  position: unset;
  float: left;
  margin-left: 27px;
}
.list_con2 .list_con_right {
  margin-top: 0;
}
.list_con2 .list_con_right span {
  display: inline-block;
  float: left;
}
.list_con2 .list_con_right span:nth-child(1) {
  font-size: 26px;
  color: #6261ff;
}
.list_con2 .list_con_right span:nth-child(2) {
  font-size: 16px;
  color: #4d4d4d;
  margin-top: 7px;
  margin-left: 12px;
}
.list .list_title2 span {
  cursor: initial;
}
.list_omg {
  margin: 75px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
}
.list_omg_one {
  display: inline-block;
  width: 168px;
  text-align: center;
}
.list_omg_one:nth-child(1),.list_omg_one:nth-child(2),.list_omg_one:nth-child(3){
  width: 140px;
}
.list_omg_one:nth-child(4),.list_omg_one:nth-child(5),.list_omg_one:nth-child(6){
  width: 196px;
}
.list_omg_one .yuan {
  width: 62px;
  height: 62px;
  background-image: linear-gradient(to right bottom, #bdbdfd, #5b5aff);
  border-radius: 50px;
  margin: auto;
  line-height: 62px;
  padding: 0;
}
.list_omg_one .yuan span {
  color: #fff;
}
.list_omg_one .yuan span:nth-child(1) {
  font-size: 22px;
}
.list_omg_one .yuan span:nth-child(2) {
  font-size: 12px;
}
.list_omg h2 {
  margin-top: 17px;
  font-size: 16px;
  color: #4d4d4d;
}
.list_omg_one>span {
  display: block;
  padding: 4px 0;
  font-size: 12px;
  color: #b4b4b4;
  height: 12px;
}
.list_omg .btn {
  width: 67px;
  height: 24px;
   border: 1px #5957ff solid; color: #5957ff; 
  font-size: 14px;
  border-radius: 20px;
  line-height: 24px;
  text-align: center;
  margin: auto;
  cursor: pointer;
}
.colorblue{color: #6261ff; cursor: pointer;}
.btn2{ cursor: unset !important;
border: 1px #b4b4b4 solid !important;
  color: #b4b4b4 !important;}
.list >>> .el-empty { padding: 0 0 40px;}
.loading{ padding: 50px 0;}
.loading >>> .el-loading-mask{ background: none;}
.loading >>> .el-loading-spinner .path{stroke: #5857ff;}
</style>