<template>
	<div class="receive_box">
		<!-- 收货弹窗 -->
		<div class="buy_close">
			<div class="el-icon-close" @click="close"></div>
		</div>
		<div class="receive_tips">
			<p class="receive_p1"><span v-show="type==1">兑换成功，</span>请填写邮寄信息</p>
			<p class="receive_p2">*请准确填写邮寄信息，一经填写不可修改<br>如有疑问请联系官网客服</p>
		</div>
		<div class="receiveBox ">
			<div class="drawBill_item">
				<span>订单编号：</span>
				<p>{{number}}</p>
			</div>
			<div class="drawBill_item">
				<span>收货人：</span>
				<input type="text" v-model="name" placeholder="请填写收货人">
			</div>
			<div class="drawBill_item">
				<span>手机号：</span>
				<input type="number" maxlength="11" v-model="mobile" placeholder="请填写手机号">
			</div>
			<div class="drawBill_item">
				<span>收货地址：</span>
				<input type="text" v-model="address" placeholder="请填写收货地址">
			</div>

			<div class="draw_btn">
				<div class="canclebtn" @click="close">取消</div>
				<div class="applybtn" @click="companySubmit">提交</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['number','type'],
		data() {
			return {
				name: '',
				mobile: '',
				address: '',
			}
		},
		mounted() {
			if (localStorage.getItem('userInfo')) {
				this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
				this.avatar = this.userInfo.thumb;
			}

		},
		methods: {
			// 提交收货信息
			companySubmit() {
				var that = this;
				if (!that.name) {
					that.$utile.prompt("error", "请填写姓名");
					return false;
				}
				if (!that.mobile) {
					that.$utile.prompt("error", "请填写手机号");
					return false;
				}
				var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
				if (!reg.test(that.mobile)) {
					that.$utile.prompt("error","您的手机号码格式不正确，请重新输入");
					return false;
				}

				if (!that.address) {
					that.$utile.prompt("error", "请填写收货地址");
					return false;
				}
				
				// 姓名，地址，电话存到缓存
				if (that.name) {
					localStorage.setItem("name",that.name)
				}
				if (that.address) {
					localStorage.setItem("address",that.address)
				}
				if (that.mobile) {
					localStorage.setItem("mobile",that.mobile)
				}
				
				var data = {
					order_no:that.number,
					name:that.name,
					address:that.address,
					mobile:that.mobile
				}
				
				this.$api.POST(this.$face.integral_subexpress,data, function(res) {
					
					
					that.$utile.prompt("success", res.msg);

					
					that.$emit('close',1);
					
					that.$emit('toNews')
					

					
				});
			},
		
			toIndexs(){
				this.$parent.toIndex();
			},
			close() {
				this.$emit('close',0);
				
			},
	
	

		}
	}
</script>

<style>
	.receive_tips{
		width: 80%;
		margin: auto;
		height: auto;
		overflow: hidden;
		position: absolute;
		top: 18px;
		left: 50%;
		transform: translate(-50%, 0);
		text-align: center;
	}
	.receive_tips p.receive_p1{
		font-size: 18px;
		color: #333333;
		line-height: 30px;
		font-weight: 600;
	}
	.receive_tips p.receive_p2{
		font-size: 14px;
		color: #999999;
		margin-top: 5px;
		line-height: 20px;
	}
	.receive_box {
		width: 500px;
		height: 410px;
		background-color: #ffffff;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.buy_close {
		height: 38px;
		text-align: right;
		font-size: 22px;
		color: #a0a1a1;
		margin-top: 15px;
		margin-right: 15px;
		line-height: 38px;
		cursor: pointer;
	}

	.receiveBox {
		width: 100%;
		height: auto;
		overflow: hidden;
		padding: 0 50px;
		box-sizing: border-box;
		margin-top: 50px;
	}
	.receiveBox2{
		margin-top: 6px;
	}

	.receiveBox .drawBill_item {
		width: 100%;
		height: auto;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
	}

	.drawBill_item span {
		display: block;
		width: 110px;
		height: auto;
		overflow: hidden;
		text-align: right;
		line-height: 40px;
	}

	.drawBill_item p {
		width: 70%;
		font-size: 16px;
		color: #333333;
		line-height: 40px;
	}

	.drawBill_item input {
		width: 70%;
		height: 40px;
		border: 1px solid #dcdfe6;
		border-radius: 4px;
		padding:0 10px;
		box-sizing: border-box;
		font-size: 16px;
		color: #333333;
	}

	.drawBill_item input::placeholder {
		color: #dcdfe6;
		font-size: 14px;
	}

	.draw_btn {
		width: 100%;
		height: auto;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 35px;
	}

	.draw_btn div {
		width: 160px;
		height: 40px;
		border-radius: 5px;
		font-size: 16px;
		line-height: 40px;
		text-align: center;
		margin: 0 10px;
	}

	.canclebtn {
		border: 1px solid #cccccc;
		color: #999999;
	}

	.applybtn {
		background: #5957ff;
		color: #ffffff;
		cursor: pointer;
	}
</style>
